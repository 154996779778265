<template>
    <div class="locked-layout">

        <div class="locked-layout__content">
            <div class="locked-layout__heading">{{ $t("login.profile.blocked") }}</div>
            <div class="locked-layout__description">{{ $t("login.profile.enteredIncorrectlyThreeTimes") }}</div>
            <AppButton class="locked-layout__button" @click="resetLogin">{{ $t("login.profile.lucky") }}</AppButton>
        </div>

        <img src="@/assets/img/locked/block-girl.png" alt="user" class="locked-layout__figure-user">

    </div>
</template>

<script>
import AppButton from "@/components/partials/AppButton.vue";

export default {
    emits: ["reset:login"],
    components: {AppButton},
    props: {
        figurePos: {
            type: Array,
            required: true,
            default: () => ([
                { x: 0, y: 0 },
            ])
        }
    },
    methods: {
        resetLogin() {
            this.$emit("reset:login")
        }
    }
}
</script>

<style src="@/assets/scss/modules/components/pages/Login/BlockedUser.scss" lang="scss" scoped></style>
